import { Component, Input, OnDestroy, input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SeFeFormFieldConfig } from 'se-fe-form-field';
import { Subject, takeUntil } from 'rxjs';

interface CustomerGetsForm {
  customerGets: number[];
  discountWhat: string;
  discountValue: number;
  discountWhatScope: string;
  howManyItems: number;
  // limitNumberOfUses: boolean;
  // maxUses: number;
}

@Component({
  selector: 'app-customer-gets',
  templateUrl: './customer-gets.component.html',
  styleUrl: './customer-gets.component.scss',
})
export class CustomerGetsComponent implements OnDestroy {
  @Input() minimumItemCount: number;
  currentOrgId = input<string>();

  protected onDestroy$ = new Subject();

  readonly form = new FormGroup({
    customerGets: new FormControl<number[]>([], [Validators.required]),
    discountWhat: new FormControl<string>(null, [Validators.required]),
    discountValue: new FormControl<number>(null),
    discountWhatScope: new FormControl<string>(null, [Validators.required]),
    howManyItems: new FormControl<number>(null),
    // limitNumberOfUses: new FormControl<boolean>(false),
    // maxUses: new FormControl<number>(null),
  });

  //#region - form field options -
  discountWhatOptions = [
    {
      value: 'percentage',
      label: 'Percentage',
    },
    {
      value: 'amount',
      label: 'Dollar Amount',
    },
    {
      value: 'free',
      label: 'Free',
    },
  ];

  discountWhatScopeOptions = [
    {
      value: 'Item',
      label: 'Specific number of items',
    },
    {
      value: 'Order',
      label: 'All eligible items',
    },
  ];

  discountWhatLimitOptions = [
    {
      label: 'Set a maximum number of uses per order',
      value: true,
    },
  ];
  //#endregion - form field options -

  //#region - form field configs -
  discountWhatConfig: SeFeFormFieldConfig = {
    label: 'At what discount?',
    required: true,
    errorMessages: {
      required: 'Please select a value.',
    },
  };

  discountAmountConfig: SeFeFormFieldConfig = {
    label: 'Amount',
    required: true,
    errorMessages: {
      required: 'Please enter an amount.',
      min: 'Value must be greater than 0.',
      pattern: 'Please enter a valid amount.',
      max: 'Value must be less than 100.',
    },
  };

  discountWhatScopeConfig: SeFeFormFieldConfig = {
    label: 'On how many items?',
    required: true,
    errorMessages: {
      required: 'Please select a scope for how many items.',
      min: 'Value must be greater than 0.',
    },
  };

  discountWhatLimitValueConfig: SeFeFormFieldConfig = {
    label: 'Maximum',
    errorMessages: {
      required: 'Please enter a maximum number of uses.',
      min: 'Value must be greater than 0.',
      pattern: 'Please enter a valid number.',
    },
  };

  discountWhatLimitConfig: SeFeFormFieldConfig = {
    label: 'Limit the number of uses per order?',
  };
  discountFormService: any;

  //#endregion - form field configs -

  get customerGetsControl(): FormControl<number[]> {
    return this.form.get('customerGets') as FormControl<number[]>;
  }

  get value(): Partial<CustomerGetsForm> {
    return this.form.value;
  }

  constructor() {
    this.setupSubscriptions();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getDiscountWhatDescription(value: string): string {
    const count = this.minimumItemCount;
    const item = count === 1 ? 'item' : 'items';
    switch (value) {
      case 'percentage':
        return 'Customer gets a percentage off all eligible items';
      case 'amount':
        return 'Customer gets a dollar amount off all eligible items';
      case 'free':
        return 'Customer gets all eligible items for free';
      case 'Item':
        return `For every ${count} eligible ${item} bought, customer gets a certain number of items at a discount`;
      case 'Order':
        return `If customer buys ${count} eligible ${item}, customer gets all other eligible items in the order at a discount`;
    }
  }

  protected updateCustomerGets(products: number[]): void {
    this.customerGetsControl.setValue(products);
  }

  protected setupSubscriptions(): void {
    this.form
      .get('discountWhat')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((value: string) => {
        if (!value) return;
        const discountValueControl = this.form.get('discountValue');
        if (value === 'amount') {
          discountValueControl.setValidators([
            Validators.required,
            Validators.min(0.01),
            Validators.pattern(/^\d+(\.\d{1,2})?$/),
          ]);
        } else if (value === 'percentage') {
          discountValueControl.setValidators([
            Validators.required,
            Validators.pattern('^(100|\\d{1,2})(\\.\\d{1,2})?$'),
            Validators.min(1),
            Validators.max(100),
          ]);
        } else {
          discountValueControl.clearValidators();
        }
        discountValueControl.updateValueAndValidity();
      });

    this.form
      .get('discountWhatScope')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((value: string) => {
        const howManyItemsControl = this.form.get('howManyItems');
        if (value === 'Item') {
          howManyItemsControl.setValidators([Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]);
        } else {
          howManyItemsControl.clearValidators();
        }
        howManyItemsControl.updateValueAndValidity();
      });

    // this.form.get('discountWhatLimit').valueChanges.pipe(takeUntil(this.onDestroy$))
    //   .subscribe((value: boolean) => {
    //   if (!value) return;
    //   const maxUsesControl = this.form.get('maxUses');
    //   if (value) {
    //     maxUsesControl.setValidators([
    //       Validators.required,
    //       Validators.min(1),
    //       Validators.pattern('^[0-9]*$')
    //     ]);
    //   } else {
    //     maxUsesControl.clearValidators();
    //   }
    //   maxUsesControl.updateValueAndValidity();
    // });
  }
}
