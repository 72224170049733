import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { Discount } from '../models/discount.model';
import { DiscountService } from './discount.service';
import { LaunchDarklyService } from './launch-darkly.service';

@Injectable({ providedIn: 'root' })
export class DiscountFormService {
  private maxNameLength = 64;
  private minCodeLength = 5;
  private maxCodeLength = 20;
  private buyXGetYEnabled = false;

  constructor(
    public datePipe: DatePipe,
    public translateService: TranslateService,
    public discountService: DiscountService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.buyXGetYEnabled = this.launchDarklyService.enabled('buy-x-get-y');
  }

  public UI_DATE_FORMAT = 'MM/dd/yyyy';
  public DB_DATE_FORMAT = 'yyyy-MM-dd';

  // DISCOUNT TYPE
  public discountTypeConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_type.label'),
    hiddenLabel: true,
    errorMessages: {
      required: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_type.errors.required'),
    },
  };

  public typeOptions = [
    {
      value: 'automatic',
      new: true,
      label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_type.automatic_label'),
      help: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_type.automatic_help'),
    },
    {
      value: 'individual',
      new: false,
      label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_type.individual_label'),
      help: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_type.individual_help'),
    },
    {
      value: 'reusable',
      new: false,
      label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_type.reusable_label'),
      help: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_type.reusable_help'),
    },
  ];

  // DISCOUNT NAME
  public discountNameConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_name.label'),
    hiddenLabel: true,
    errorMessages: {
      required: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_name.errors.required'),
      maxlength: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_name.errors.maxlength', {
        maxLength: this.maxNameLength,
      }),
    },
  };

  public discountCodeConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_code.label'),
    hiddenLabel: true,
    errorMessages: {
      required: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_code.errors.required'),
      pattern: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_code.errors.pattern'),
      minlength: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_code.errors.minlength', {
        minLength: this.minCodeLength,
      }),
      maxlength: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_code.errors.maxlength', {
        maxLength: this.maxCodeLength,
      }),
    },
  };

  // DISCOUNT VALUE
  public valueTypeConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_value.value_type.label'),
    hiddenLabel: true,
    errorMessages: {
      required: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_value.value_type.errors.required'),
    },
  };

  public valueAmountConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_value.value_amount.label'),
    errorMessages: {
      required: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_value.value_amount.errors.required'),
      min: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_value.value_amount.errors.min'),
      pattern: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_value.value_amount.errors.pattern'),
    },
  };

  public valueTypeOptions = [
    {
      value: 'amount',
      label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_value.value_type.dollars_label'),
    },
    {
      value: 'percentage',
      label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_value.value_type.percent_label'),
    },
  ];

  public maxValueConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_value.maximum_discount_value.label'),
    errorMessages: {
      required: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_value.maximum_discount_value.errors.required'
      ),
      min: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_value.maximum_discount_value.errors.min'),
      pattern: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_value.maximum_discount_value.errors.pattern'
      ),
    },
  };

  // DISCOUNT SCOPE
  public discountScopeConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_scope.discount_scope.label'),
    hiddenLabel: true,
    errorMessages: {
      required: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_scope.discount_scope.errors.required'),
    },
  };

  public discountScopeOptions = [
    {
      value: 'Order',
      label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_scope.discount_scope.order_label'),
    },
    {
      value: 'Item',
      label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_scope.discount_scope.item_label'),
    },
  ];

  public discountedItemsConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_scope.discounted_item_variation_ids.label'),
    errorMessages: {
      required: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_scope.discounted_item_variation_ids.errors.required'
      ),
    },
  };

  public maxEligibleConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_scope.max_eligible_item_type.label'),
    errorMessages: {
      required: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_scope.max_eligible_item_type.errors.required'
      ),
    },
  };

  public maxEligibleOptions = [
    {
      value: 'one',
      label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_scope.max_eligible_item_type.one_label'),
    },
    {
      value: 'all',
      label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_scope.max_eligible_item_type.all_label'),
    },
    {
      value: 'set',
      label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_scope.max_eligible_item_type.set_label'),
    },
  ];

  public maxEligibleCountConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_scope.max_eligible_item_count.label'),
    hiddenLabel: true,
    errorMessages: {
      required: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_scope.max_eligible_item_count.errors.required'
      ),
      min: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_scope.max_eligible_item_count.errors.min'),
      pattern: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_scope.max_eligible_item_count.errors.pattern'
      ),
    },
  };

  // DISCOUNT REQUIREMENTS
  public requirementTypeConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_requirements.requirement_type.label'),
    hiddenLabel: true,
    errorMessages: {
      required: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_requirements.requirement_type.errors.required'
      ),
    },
  };

  public requirementTypeOptions = [
    {
      value: 'None',
      label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_requirements.requirement_type.none_label'),
    },
    {
      value: 'PurchasePrice',
      label: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_requirements.requirement_type.price_label'
      ),
    },
    {
      value: 'Quantity',
      label: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_requirements.requirement_type.quantity_label'
      ),
    },
  ];

  public minSaleTotalConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_requirements.minimum_sale_total.label'),
    hiddenLabel: true,
    errorMessages: {
      required: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_requirements.minimum_sale_total.errors.required'
      ),
      min: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_requirements.minimum_sale_total.errors.min'),
      pattern: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_requirements.minimum_sale_total.errors.pattern'
      ),
    },
  };

  public requiredItemsConfig = {
    label: this.translateService.instant(
      'DISCOUNTS.DISCOUNT_FORM.discount_requirements.required_item_variation_ids.label'
    ),
    errorMessages: {
      required: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_requirements.required_item_variation_ids.errors.required'
      ),
    },
  };

  public requiredItemsQuantityConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_requirements.required_items_quantity.label'),
    errorMessages: {
      required: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_requirements.required_items_quantity.errors.required'
      ),
      pattern: this.translateService.instant(
        'DISCOUNTS.DISCOUNT_FORM.discount_requirements.required_items_quantity.errors.pattern'
      ),
    },
  };

  // MAXIMUM REDEEMABLE (REUSABLE CODES ONLY)
  public maxUseConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.max_redeemable.label'),
    hiddenLabel: true,
    errorMessages: {
      required: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.max_redeemable.max_use.errors.required'),
    },
  };

  public maxUseOptions = [
    {
      value: 'unlimited',
      label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.max_redeemable.max_use.unlimited_label'),
    },
    {
      value: 'limited',
      label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.max_redeemable.max_use.limited_label'),
    },
  ];

  public maxUseCountConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.max_redeemable.max_use_count.label'),
    hiddenLabel: true,
    errorMessages: {
      required: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.max_redeemable.max_use_count.errors.required'),
      pattern: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.max_redeemable.max_use_count.errors.pattern'),
      min: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.max_redeemable.max_use_count.errors.min'),
    },
  };

  // CODE COUNT (INDIVIDUAL CODES ONLY)
  public codeCountConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.code_count.label'),
    hiddenLabel: true,
    errorMessages: {
      required: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.code_count.errors.required'),
      pattern: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.code_count.errors.pattern'),
    },
  };

  public additionalCodeConfig = {
    label: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.additional_code_count.label'),
    errorMessages: {
      pattern: this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.additional_code_count.errors.pattern'),
    },
  };

  addBuyXGetYOption(): void {
    if (this.buyXGetYEnabled && !this.valueTypeOptions.some((option) => option.value === 'buyXGetY')) {
      this.valueTypeOptions.push({
        value: 'buyXGetY',
        label: 'Buy X Get Y',
      });
    }
  }

  removeBuyXGetYOption(): void {
    if (this.valueTypeOptions.some((option) => option.value === 'buyXGetY')) {
      this.valueTypeOptions = this.valueTypeOptions.filter((option) => option.value !== 'buyXGetY');
    }
  }

  public getStepsForType(discount_type: string): string[] {
    switch (discount_type) {
      case 'individual':
        return [
          'discount_type',
          'discount_name',
          'discount_value',
          'discount_scope',
          'discount_requirements',
          'discount_dates',
          'code_count',
        ];
      case 'reusable':
        return [
          'discount_type',
          'discount_name',
          'discount_code',
          'discount_value',
          'discount_scope',
          'discount_requirements',
          'max_redeemable',
          'discount_dates',
        ];
      case 'automatic':
        return [
          'discount_type',
          'discount_name',
          'discount_value',
          'discount_scope',
          'discount_requirements',
          'max_redeemable',
          'discount_dates',
        ];
    }
    return [];
  }

  getBuyXGetYSteps(steps: string[]): string[] {
    const discountValueIndex = steps.indexOf('discount_value');
    steps.splice(discountValueIndex + 1);
    return [...steps, 'customer_buys', 'customer_gets', 'max_redeemable', 'discount_dates'];
  }

  // DISCOUNT TYPE
  public getDiscountTypeFormGroup(model: Discount): UntypedFormGroup {
    const type = this.discountService.getDiscountType(model);
    return new UntypedFormGroup({
      type: new UntypedFormControl(type, [Validators.required]),
    });
  }

  // DISCOUNT NAME
  public getDiscountNameFormGroup(model: Discount): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl(model.name, [Validators.required, Validators.maxLength(this.maxNameLength)]),
    });
  }

  // DISCOUNT CODE (REUSABLE CODES ONLY)
  public getDiscountCodeFormGroup(model: Discount): UntypedFormGroup {
    return new UntypedFormGroup({
      supplied_code: new UntypedFormControl(model.supplied_code, [
        Validators.required,
        Validators.pattern(/^(?:[a-zA-Z0-9]+)?$/), // alphanumeric, no spaces or special characters
        Validators.minLength(this.minCodeLength),
        Validators.maxLength(this.maxCodeLength),
      ]),
    });
  }

  // DISCOUNT VALUE
  public getDiscountValueFormGroup(model: Discount): UntypedFormGroup {
    const value = this.discountService.getNumericValue(model);
    return new UntypedFormGroup({
      value_type: new UntypedFormControl(model.discount_type, Validators.required),
      value_amount: new UntypedFormControl(value, Validators.required),
      maximum_discount_value: new UntypedFormControl(model.maximum_discount_value, Validators.required),
    });
  }

  public setValueValidation(valueForm: UntypedFormGroup, showMaxValue: boolean): void {
    const valueType = valueForm.controls.value_type.value;
    if (valueType === 'amount') {
      valueForm.controls.value_amount.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
        Validators.min(1),
        Validators.max(999_999_999.99),
      ]);
      this.setMaxValueValidation(valueForm, false);
    } else if (valueType === 'percentage') {
      valueForm.controls.value_amount.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
        Validators.min(1),
        Validators.max(100),
      ]);
      this.setMaxValueValidation(valueForm, showMaxValue);
    } else if (valueType === 'buyXGetY') {
      valueForm.controls.value_amount.clearValidators();
      valueForm.controls.value_amount.updateValueAndValidity();
      this.setMaxValueValidation(valueForm, false);
    }
  }

  public setMaxValueValidation(valueForm: UntypedFormGroup, showMaxValue: boolean): void {
    if (showMaxValue) {
      valueForm.controls.maximum_discount_value.setValidators([
        Validators.required,
        Validators.min(1),
        Validators.pattern(/^\d+(?:\.\d{0,2})?$/), // Numeric, up to 2 decimal places
      ]);
    } else {
      valueForm.controls.maximum_discount_value.setValue(null);
      valueForm.controls.maximum_discount_value.setValidators([]);
    }
    valueForm.controls.maximum_discount_value.updateValueAndValidity();
  }

  // DISCOUNT SCOPE
  public getDiscountScopeFormGroup(model: Discount): UntypedFormGroup {
    const maxEligibleItemType =
      model.discount_scope === 'Item' ? this.getMaxEligibleTypeValue(model.max_eligible_items) : null;
    const maxEligibleItemCount = maxEligibleItemType === 'set' ? model.max_eligible_items : null;
    return new UntypedFormGroup({
      scope_type: new UntypedFormControl(model.discount_scope, Validators.required),
      discounted_item_variation_ids: new UntypedFormControl(model.discounted_item_variation_ids),
      max_eligible_item_type: new UntypedFormControl(maxEligibleItemType),
      max_eligible_item_count: new UntypedFormControl(maxEligibleItemCount),
    });
  }

  public setScopeValidation(scopeForm: UntypedFormGroup, itemized: boolean): void {
    if (scopeForm.controls.scope_type.value === 'Item') {
      scopeForm.controls.discounted_item_variation_ids.setValidators([Validators.required]);
      this.setMaxEligibleValidation(scopeForm, true);
    } else {
      scopeForm.controls.discounted_item_variation_ids.setValue(null);
      scopeForm.controls.discounted_item_variation_ids.setValidators([]);
      this.setMaxEligibleValidation(scopeForm, itemized);
    }
    scopeForm.controls.discounted_item_variation_ids.updateValueAndValidity();
  }

  public setMaxEligibleValidation(scopeForm: UntypedFormGroup, itemized: boolean): void {
    if (itemized) {
      scopeForm.controls.max_eligible_item_type.setValidators([Validators.required]);
    } else {
      scopeForm.controls.max_eligible_item_type.setValue(null);
      scopeForm.controls.max_eligible_item_type.setValidators([]);
    }
    scopeForm.controls.max_eligible_item_type.updateValueAndValidity();
    this.setMaxEligibleCountValidation(scopeForm);
  }

  public setMaxEligibleCountValidation(scopeForm: UntypedFormGroup): void {
    if (scopeForm.controls.scope_type.value === 'Item' && scopeForm.controls.max_eligible_item_type.value === 'set') {
      scopeForm.controls.max_eligible_item_count.setValidators([
        Validators.required,
        Validators.min(2),
        Validators.pattern(/^0*[1-9]\d*$/), // positive whole numbers
      ]);
    } else {
      scopeForm.controls.max_eligible_item_count.setValue(null);
      scopeForm.controls.max_eligible_item_count.setValidators([]);
    }
    scopeForm.controls.max_eligible_item_count.updateValueAndValidity();
  }

  public getMaxEligibleCountValue(scopeFormValues: any): number {
    if (!scopeFormValues.max_eligible_item_type) {
      return null;
    }
    switch (scopeFormValues.max_eligible_item_type) {
      case 'one':
        return 1;
      case 'all':
        return 0;
      case 'set':
        return scopeFormValues.max_eligible_item_count;
    }
    return 0;
  }

  public getMaxEligibleTypeValue(maxEligibleItems: number): string {
    if (maxEligibleItems == null) {
      return null;
    }
    switch (maxEligibleItems) {
      case 1:
        return 'one';
      case 0:
        return 'all';
      default:
        return 'set';
    }
  }

  // DISCOUNT REQUIREMENTS
  public getDiscountRequirementsFormGroup(model: Discount): UntypedFormGroup {
    return new UntypedFormGroup({
      requirement_type: new UntypedFormControl(model.requirement_type || 'None', Validators.required),
      minimum_sale_total: new UntypedFormControl(model.minimum_sale_total),
      required_item_variation_ids: new UntypedFormControl(model.required_item_variation_ids),
      required_items_quantity: new UntypedFormControl(model.required_items_quantity),
    });
  }

  public setRequirementsValidation(requirementsForm: UntypedFormGroup): void {
    // set PurchasePrice input validation
    if (requirementsForm.controls.requirement_type.value === 'PurchasePrice') {
      requirementsForm.controls.minimum_sale_total.setValidators([
        Validators.required,
        Validators.min(1),
        Validators.pattern(/^\d+(?:\.\d{0,2})?$/), // Numeric, up to 2 decimal places
      ]);
    } else {
      requirementsForm.controls.minimum_sale_total.setValue(null);
      requirementsForm.controls.minimum_sale_total.setValidators([]);
    }

    // set Quantity input validation
    if (requirementsForm.controls.requirement_type.value === 'Quantity') {
      requirementsForm.controls.required_item_variation_ids.setValidators([Validators.required]);
      requirementsForm.controls.required_items_quantity.setValidators([
        Validators.required,
        Validators.pattern(/^0*[1-9]\d*$/), // positive whole numbers
      ]);
    } else {
      requirementsForm.controls.required_item_variation_ids.setValue(null);
      requirementsForm.controls.required_item_variation_ids.setValidators([]);
      requirementsForm.controls.required_items_quantity.setValue(null);
      requirementsForm.controls.required_items_quantity.setValidators([]);
    }

    requirementsForm.controls.minimum_sale_total.updateValueAndValidity();
    requirementsForm.controls.required_item_variation_ids.updateValueAndValidity();
    requirementsForm.controls.required_items_quantity.updateValueAndValidity();
  }

  // MAXIMUM REDEEMABLE (REUSABLE CODES ONLY)
  public getMaxRedeemableFormGroup(model: Discount, isEditing: boolean): UntypedFormGroup {
    const max_use_value = model.code_max_usage ? 'limited' : 'unlimited';
    const maxRedeemableForm = new UntypedFormGroup({
      max_use_count: new UntypedFormControl(model.code_max_usage),
      max_use: new UntypedFormControl(isEditing ? max_use_value : null, Validators.required),
    });

    if (model.code_max_usage || max_use_value === 'limited') {
      maxRedeemableForm.controls.max_use_count.setValidators([Validators.required]);
    }

    return maxRedeemableForm;
  }

  public setMaxRedeemableValidation(maxRedeemableForm: UntypedFormGroup, used_code_count = 0): void {
    if (maxRedeemableForm.controls.max_use.value === 'limited') {
      maxRedeemableForm.controls.max_use_count.setValidators([
        Validators.required,
        Validators.min(used_code_count),
        Validators.pattern(/^0*[1-9]\d*$/), // positive whole numbers
      ]);
    } else {
      maxRedeemableForm.controls.max_use_count.setValue(null);
      maxRedeemableForm.controls.max_use_count.setValidators([]);
    }
    maxRedeemableForm.controls.max_use_count.updateValueAndValidity();
  }

  // DISCOUNT DATES
  public getDiscountDatesFormGroup(model: Discount): UntypedFormGroup {
    const datesFormGroup = new UntypedFormGroup({
      starts_at: new UntypedFormControl('', Validators.required),
      expires_at: new UntypedFormControl(''),
    });
    this.setDiscountDates(model, datesFormGroup);
    return datesFormGroup;
  }

  public setDiscountDates(model: Discount, datesFormGroup: UntypedFormGroup) {
    if (model.starts_at) {
      const start_value = this.datePipe.transform(model.starts_at, this.UI_DATE_FORMAT);
      const end_value = model.expires_at ? this.datePipe.transform(model.expires_at, this.UI_DATE_FORMAT) : '';
      datesFormGroup.setValue({
        starts_at: start_value,
        expires_at: end_value,
      });
      if (!model.expires_at) {
        datesFormGroup.controls.expires_at.setValidators([]);
      }
    } else {
      datesFormGroup.setValue(this.getDefaultDateRange());
    }
  }

  public getDefaultDateRange(): any {
    const today = this.datePipe.transform(Date.now(), this.UI_DATE_FORMAT);
    return {
      starts_at: today,
      expires_at: '',
    };
  }

  public formatDateForSubmission(dateString: string): string {
    const date = DateTime.fromFormat(dateString, this.UI_DATE_FORMAT);
    if (date.isValid) {
      return date.toFormat(this.DB_DATE_FORMAT);
    } else {
      return '';
    }
  }

  // CODE COUNT (INDIVIDUAL CODES ONLY)
  public getCodeCountFormGroup(model: Discount): UntypedFormGroup {
    return new UntypedFormGroup({
      code_count: new UntypedFormControl(model.code_count, [Validators.required, Validators.pattern(/^\d+$/)]),
      additional_code_count: new UntypedFormControl('', [Validators.pattern(/^\d+$/)]),
    });
  }

  public hasUniqueNameError(errors: string[]): boolean {
    if (!errors) {
      return false;
    }
    const errorMsg = this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_name.unique.server_error');
    return errors.indexOf(errorMsg) >= 0 || errors.includes('uniqueName');
  }

  public updateDiscountValidators(control: AbstractControl, maxValue: number): void {
    control.setValidators([
      Validators.required,
      Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
      Validators.min(1),
      Validators.max(maxValue),
      this.percentageDiscountValidator(),
    ]);
    if (control.value > maxValue) {
      control.markAsTouched();
      control.markAsDirty();
    }
    control.updateValueAndValidity();
  }

  /**
   * Validator function to check if the amount entered in
   * What is the value of the discount? is a valid percentage
   * @returns A ValidatorFn that validates the percentage value.
   */

  public percentageDiscountValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const form = control.parent;
      if (!form) {
        return null;
      }
      const discountType = form.get('discountValueType')?.value;
      if (discountType !== 'percentage') {
        return null;
      }
      const value = control.value;
      if (value === null || value === undefined || value === '') {
        return null; // No error if the field is empty
      }
      if (isNaN(value) || value < 0 || value > 100) {
        return { percentageExceedsLimit: true };
      }
      return null;
    };
  }
}
