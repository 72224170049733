import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
// 3rd party packages
import { BehaviorSubject, firstValueFrom, forkJoin, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
// Services
import { SeAnalyticsService } from 'se-analytics';
// SE FE library packages
import { SeFeApiService } from 'se-fe-api';
import { SeFeConfirmService } from 'se-fe-confirm';
import { SeFePaginationChange, SeFePaginationComponent, SeFePaginationOptions } from 'se-fe-pagination';
import { SeFeTableComponent, SeFeTableDataSource, SeFeTableSortDirective } from 'se-fe-table';
import { SeFeToastService, SeFeToastTypes } from 'se-fe-toast';
import { FiscalYear } from 'src/app/models/fiscal-year.model';
import { FiscalYearService } from 'src/app/services/fiscal-year.service';
import { environment } from 'src/environments/environment';
import { CURRENT_ORG_ID } from '../../providers/current-org-id.provider';
import { Discount } from '../models/discount.model';
// Components
import { QuickFiltersComponent } from '../quick-filters/quick-filters.component';
import { DiscountService } from '../services/discount.service';

enum LoadStage {
  LoadWithFiscalYearSelected = 1,
  LoadWithNoQuickFilterSelected = 2,
  LoadingComplete = 3,
}

@Component({
  selector: 'app-discount-list',
  templateUrl: './discount-list.component.html',
  styleUrls: ['./discount-list.component.scss'],
})
export class DiscountListComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    @Inject(CURRENT_ORG_ID) public currentOrgId: number,
    private apiService: SeFeApiService,
    public confirmService: SeFeConfirmService,
    public discountService: DiscountService,
    public fiscalYearService: FiscalYearService,
    public toastService: SeFeToastService,
    public translateService: TranslateService,
    public seAnalyticsService: SeAnalyticsService,
    public route: Router
  ) {
    this.seAnalyticsService.trackEvent('pageView', {
      depth1: 'HQ',
      depth2: 'Discounts',
    });
  }

  public discountData: Discount[] = [];
  public discountDataLoading = false;
  public discountDataSource: SeFeTableDataSource;
  public discountHeaderSubtitle = '';
  public hasActiveFilters = false;
  public hideEmptyState = true;
  public forceEmptyState = false;
  public loadStage = LoadStage.LoadWithFiscalYearSelected;
  public pageLoading = true;
  public paginationOptions: SeFePaginationOptions;
  public searchTerm = '';
  public subscriptions: Subscription;
  public displayedColumns = new BehaviorSubject<string[]>([
    'name',
    'discount_value',
    'starts_at',
    'discount_style',
    'code_max_usage',
    'used_code_count',
    'status',
    'delete',
  ]);

  @ViewChild(SeFeTableComponent)
  public discountTable!: SeFeTableComponent<ElementRef>;
  @ViewChild(SeFeTableSortDirective) public sorter!: SeFeTableSortDirective;
  @ViewChild(SeFePaginationComponent)
  public pagination!: SeFePaginationComponent;
  @ViewChild(QuickFiltersComponent) public quickFilter: QuickFiltersComponent;

  public async ngOnInit(): Promise<void> {
    const paginationLabel = this.translateService.instant('DISCOUNTS.LIST_VIEW.PAGINATION.label');
    const paginationName = this.translateService.instant('DISCOUNTS.LIST_VIEW.PAGINATION.name');
    this.paginationOptions = { label: paginationLabel, name: paginationName };
    this.subscriptions = new Subscription();
    this.discountDataSource = new SeFeTableDataSource();
    this.subscriptions.add(
      forkJoin([this.fiscalYearService.find()]).subscribe(([fiscalYear]) => {
        this.createDiscountSubtitleHeader(fiscalYear);
        this.quickFilter.setFiscalYear(fiscalYear);
      })
    );
    await this.loadDiscountData();
  }

  public ngAfterViewInit(): void {
    this.discountTable.dataSource = this.discountDataSource;
    [
      this.sorter.seFeSortChange.subscribe(async () => {
        await this.loadDiscountData().finally(() => {
          this.reloadTable();
        });
      }),
      this.quickFilter.quickFilterChange.pipe(debounceTime(500)).subscribe(async (filter) => {
        await this.loadDiscountData().finally(() => {
          if (this.loadStage === LoadStage.LoadWithFiscalYearSelected) {
            if (!this.discountData.length) {
              this.quickFilter.unset();
              this.loadStage = LoadStage.LoadWithNoQuickFilterSelected;
            } else {
              this.pageLoading = false;
              this.reloadTable();
              this.loadStage = LoadStage.LoadingComplete;
            }

            return;
          }

          if (this.loadStage === LoadStage.LoadWithNoQuickFilterSelected) {
            if (!this.discountData.length) {
              this.forceEmptyState = true;
            }

            this.pageLoading = false;
            this.loadStage = LoadStage.LoadingComplete;
            this.reloadTable();
            return;
          }

          if (this.loadStage === LoadStage.LoadingComplete) {
            this.pageLoading = false;
            this.reloadTable();
          }
        });
      }),
    ].forEach((sub) => this.subscriptions.add(sub));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async loadDiscountData(): Promise<Discount[]> {
    this.discountDataLoading = true;
    const params = {
      ...this.baseParams,
      ...this.paginationParams,
      ...this.searchParams,
      ...this.sortParams,
    };
    if (this.quickFilter && this.quickFilter.selectedDateFilter && this.quickFilter.selectedDateFilter.length) {
      const range_start = this.quickFilter.selectedDateRange.start_date;
      const range_end = this.quickFilter.selectedDateRange.end_date;
      params.active_during_range = `${range_start},${range_end}`;
    }
    if (this.quickFilter && this.quickFilter.selectedStatuses.length) {
      params.discount_code_statuses = this.quickFilter.selectedStatuses.toString();
    }
    const results = await firstValueFrom(this.apiService.get(this.discountService.endpoint, { params })).finally(() => {
      this.discountDataLoading = false;
    });
    this.discountData = results.result;
    this.discountDataSource.data = this.discountData;
    this.setPaginationOptions(results.metadata.pagination);

    return results.result;
  }

  public async search(searchTerm: string): Promise<void> {
    this.searchTerm = searchTerm.trim(); // Prevents empty searches
    if (this.searchTerm.length === 0) {
      this.searchTerm = '';
      this.hasActiveFilters = false;
      this.pagination.initializePagination();
      await this.loadDiscountData();
      return;
    }
    this.discountDataLoading = true;
    try {
      await this.loadDiscountData();
    } finally {
      this.reloadTable();
      this.discountDataLoading = false;
    }
  }

  public async handlePagination(paginationData: SeFePaginationChange): Promise<void> {
    this.paginationOptions.limit = paginationData.limit;
    this.paginationOptions.currentPage = paginationData.currentPage;
    await this.loadDiscountData();
  }

  public async deleteDiscount(id: number, name: string): Promise<void> {
    const discount = new Discount({ id, name });
    await this.discountService
      .destroy(discount)
      .then(() => {
        const successMessage = this.translateService.instant('DISCOUNTS.LIST_VIEW.DELETE_MODAL.success', { name });
        this.createToast('Success', successMessage);
      })
      .catch((e) => {
        const errorMessage = this.translateService.instant(`DISCOUNTS.LIST_VIEW.DELETE_MODAL.ERROR.${e.status}`, {
          name,
        });
        this.createToast('Error', errorMessage);
      });
  }

  public confirmDelete(event: MouseEvent, id: number, name: string) {
    const trigger = event.target as HTMLElement;
    this.confirmService.confirm({
      message: this.translateService.instant('DISCOUNTS.LIST_VIEW.DELETE_MODAL.message', { name }),
      actions: [
        {
          label: this.translateService.instant('DISCOUNTS.LIST_VIEW.DELETE_MODAL.cancel'),
          action: () => null,
        },
        {
          label: this.translateService.instant('DISCOUNTS.LIST_VIEW.DELETE_MODAL.confirm_action'),
          destructive: true,
          action: () => {
            this.discountDataLoading = true;
            this.deleteDiscount(id, name).then(async () => {
              await this.loadDiscountData().finally(() => {
                this.reloadTable();
              });

              this.seAnalyticsService.trackEvent('seEvent', {
                depth1: 'HQ',
                depth2: 'Discounts',
                action: 'DeleteDiscount',
                event_type: 8,
              });
            });
          },
        },
      ],
      triggerEl: trigger,
    });
  }

  public addDiscount() {
    this.seAnalyticsService.trackEvent('seEvent', {
      depth1: 'HQ',
      depth2: 'Discounts',
      action: 'Add Discount',
      event_type: 8,
    });
    this.route.navigate(['discounts/new']);
  }

  public reloadTable() {
    this.pagination.initializePagination();
    this.hasActiveFilters =
      !!this.quickFilter.selectedDateFilter || !!this.quickFilter.selectedStatuses.length || !!this.searchTerm;
    this.hideEmptyState =
      (this.pageLoading || this.discountDataLoading || !!this.discountData.length || this.hasActiveFilters) &&
      !this.forceEmptyState;
  }

  public financialSettingsUrl(): string {
    return `${environment.snFrontendBaseUrl}/org/${this.currentOrgId}/financial_settings/account-detail`;
  }

  // Build Query Params
  private get baseParams(): any {
    return {
      organization_id: this.currentOrgId,
    };
  }

  private get paginationParams(): any {
    return {
      page: this.paginationOptions.currentPage || 1,
      per_page: this.paginationOptions.limit || 25,
    };
  }

  private get searchParams(): any {
    if (!this.searchTerm) {
      return {};
    }
    const normalizedSearchTerm = this.searchTerm.trim().replace(/[\^$.*+?()[\]{}|\\_]/g, '\\$&');
    const encodedSearchTerm = encodeURIComponent(normalizedSearchTerm);
    console.log('Search term sent to backend:', encodedSearchTerm);
    return {
      search_fields: 'discount_code_definitions.name,supplied_code',
      search_term: encodedSearchTerm,
    };
  }

  private get sortParams(): any {
    const sortParams = {};
    const sortColumnApiName =
      this.sorter && this.sorter.direction ? 'order[' + this.sorter.active + ']' : 'order[created_at]';
    sortParams[sortColumnApiName] = this.sorter && this.sorter.direction ? this.sorter.direction : 'desc';
    return sortParams;
  }

  // Private methods
  public createDiscountSubtitleHeader(fiscalYear: FiscalYear): void {
    const startDate = this.fiscalYearService.fiscalYearStartDate(fiscalYear);
    const endDate = this.fiscalYearService.fiscalYearEndDate(fiscalYear);

    if (!startDate || !endDate) {
      console.error('Invalid fiscal year dates. Cannot create subtitle header.', {
        fiscalYear,
        startDate,
        endDate,
      });
      this.discountHeaderSubtitle = this.translateService.instant('DISCOUNTS.LIST_VIEW.subtitle_error');
      return;
    }

    const formattedStartDate = DateTime.fromJSDate(startDate).toFormat('MMM d');
    const formattedEndDate = DateTime.fromJSDate(endDate).toFormat('MMM d');
    this.discountHeaderSubtitle = this.translateService.instant('DISCOUNTS.LIST_VIEW.subtitle', {
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    });
  }

  private setPaginationOptions(paginationData: { current_page: number; total: number }): void {
    this.paginationOptions.currentPage = paginationData.current_page;
    this.paginationOptions.total = paginationData.total;
  }

  private createToast(toastType: string, message: string) {
    this.toastService.addToast({
      type: SeFeToastTypes[toastType],
      message,
    });
  }
}
