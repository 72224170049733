<section *ngIf="loading" class="pl-pane__container--horizontal">
  <svg class="pl-spinner">
    <use xlink:href="#svg-spinner"></use>
  </svg>
</section>

<section *ngIf="!loading" class="pl-pane__container--horizontal">
  <div class="pl-pane__header">
    <div pl-grid="tight">
      <div pl-grid="grow@phone" pl-grid-el>
        <h1 class="pl-heading--xlarge pl-nowrap" [translate]="'LIST_VIEW.title'"></h1>
      </div>
      <div pl-grid="shrink@phone" pl-grid-el>
        <div class="show@tablet">
          <se-fe-button seFeDataEmphasis="high" (click)="toggleItemModal()">
            {{ 'LIST_VIEW.add_sale_item' | translate }}
          </se-fe-button>
        </div>
        <div class="hide@tablet">
          <se-fe-button seDataEmphasis="high" (click)="toggleItemModal()">
            {{ 'LIST_VIEW.add' | translate }}
          </se-fe-button>
        </div>
      </div>
    </div>
  </div>
  <div class="pl-pane__info">
    <div class="pl-centered-container--max-width">
      <se-fe-card>
        <se-fe-card-content>
          <!-- No items empty state -->
          <div *ngIf="listIsEmpty()">
            <se-fe-empty-state seFeDataTitle="{{ 'LIST_VIEW.no_sale_items' | translate }}">
              <se-fe-icon seFeEmptyStateIcon seFeDataName="price_tag_thin" seFeDataSize="950"></se-fe-icon>
              <span [translate]="'LIST_VIEW.empty_state_description'"></span>
              <a
                class="pl-link"
                [translate]="'LIST_VIEW.learn_more'"
                href="https://sportsengine.elevio.help/en/articles/935"
                target="_blank"></a>
              <se-fe-button seFeEmptyStateAction seFeDataEmphasis="high" (click)="toggleItemModal()">
                {{ 'LIST_VIEW.add_sale_item' | translate }}
              </se-fe-button>
            </se-fe-empty-state>
          </div>

          <se-fe-toolbar
            *ngIf="items.length || searchTerm || searchDirtied"
            [seFeDataSearchMinLength]="1"
            [seFeDataQuery]="searchTerm"
            (seFeQueryChange)="search($event)"
            [seFeDataSearchDebounce]="300"></se-fe-toolbar>

          <div class="pl-pane--collapse">
            <div class="pl-data-table__container">
              <!-- Search empty state -->
              <div *ngIf="searchedListIsEmpty()">
                <se-fe-empty-state seFeDataTitle="{{ 'LIST_VIEW.no_results' | translate }}">
                  <se-fe-icon seFeEmptyStateIcon seFeDataName="search_thin" seFeDataSize="950"></se-fe-icon>
                  {{ 'LIST_VIEW.change_search_terms' | translate }}
                </se-fe-empty-state>
              </div>

              <div *ngIf="items.length" class="pl-data-table__container">
                <table class="sn-table" id="item-list-table">
                  <thead>
                    <tr>
                      <th
                        (click)="sortColumns('created_at')"
                        [ngClass]="{ 'sn-sortable-active': isActiveColumn('created_at') }"
                        class="sn-sortable-column">
                        <span [translate]="'LIST_VIEW.created'"></span>
                        <se-fe-icon
                          [seFeDataName]="setSortArrow('created_at')"
                          seFeDataSize="200"
                          style="margin-top: -0.5em"></se-fe-icon>
                      </th>
                      <th
                        (click)="sortColumns('name')"
                        [ngClass]="{ 'sn-sortable-active': isActiveColumn('name') }"
                        class="sn-sortable-column">
                        <span [translate]="'LIST_VIEW.name'"></span>
                        <se-fe-icon
                          [seFeDataName]="setSortArrow('name')"
                          seFeDataSize="200"
                          style="margin-top: -0.5em"></se-fe-icon>
                      </th>
                      <th *ngIf="showVariations" [translate]="'LIST_VIEW.item_variations'"></th>
                      <th class="sn-right-align">
                        <span [translate]="'LIST_VIEW.price'"></span>
                      </th>
                      <th *ngIf="hasSku" [translate]="'LIST_VIEW.sku'"></th>
                      <th [translate]="'LIST_VIEW.sold'"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of items">
                      <td>{{ item.getDefaultVariation()?.created_at | date }}</td>
                      <td>
                        <a [routerLink]="[item.id, 'detail']" class="pl-link">
                          {{ item.name }}
                        </a>
                      </td>
                      <td *ngIf="showVariations">
                        {{ this.moreThanOneVariation(item.id) ? this.itemsStatistics[item.id].variations_count : '--' }}
                      </td>
                      <td
                        *ngIf="moreThanOneVariation(item.id)"
                        class="sn-right-align"
                        [translate]="'LIST_VIEW.prices_may_vary'"></td>
                      <td
                        *ngIf="!moreThanOneVariation(item.id)"
                        class="sn-right-align"
                        [translate]="'LIST_VIEW.variation_price'"
                        [translateParams]="{ price: item.getDefaultVariation().price || 0.0 | currency }"></td>
                      <td *ngIf="hasSku">{{ item.sku || '--' }}</td>
                      <td>{{ itemsStatistics[item.id]?.sold_count ?? 0 }}</td>
                    </tr>
                  </tbody>
                  <div [ngClass]="{ 'sn-table-overlay': showOverlay }"></div>
                </table>
              </div>

              <div *ngIf="items.length">
                <hr class="pl-divider" />
                <app-pagination [resultType]="'LIST_VIEW.title' | translate"></app-pagination>
              </div>
            </div>
          </div>
        </se-fe-card-content>
      </se-fe-card>
    </div>
  </div>
</section>
<div *ngIf="showItemModal" class="pl-overlay">
  <sale-item-element
    [organizationId]="currentOrgId"
    [earlyLatePricingEnabled]="earlyLatePricingEnabled"
    [showTaxCode]="showTaxCode"
    (closeSaleItemModal)="toggleItemModal()"
    (success)="onSuccess($event.detail)"
    show-sale-item-type="true"></sale-item-element>
</div>
