import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

// configuration
// models
import { Discount } from '../models/discount.model';

// components
import { EditableStepComponent } from '../editable-step/editable-step.component';

// services
import { SeFeScrollService, SeFeScrollToAlignment } from 'se-fe-scroll';
import { DiscountFormService } from '../services/discount-form.service';
import { DiscountService } from '../services/discount.service';

// providers
import { CURRENT_ORG_ID } from '../../providers/current-org-id.provider';

// third party packages
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SeFeFormFieldConfig } from 'se-fe-form-field';
import { SeFeTableComponent, SeFeTableDataSource } from 'se-fe-table';
import { SeFeToastService, SeFeToastTypes } from 'se-fe-toast';
import { ItemVariationService } from '../../sale-items/services/item-variation.service';
import { CustomerBuysComponent } from '../customer-buys/customer-buys.component';
import { CustomerGetsComponent } from '../customer-gets/customer-gets.component';

@Component({
  selector: 'app-discount-form',
  templateUrl: './discount-form.component.html',
  styleUrls: ['./discount-form.component.scss'],
})
export class DiscountFormComponent implements OnInit, OnDestroy {
  @Input() public discount: Discount;
  @Input() public formMode: string;
  @Output() public updateSummary = new EventEmitter<void>();

  public discountForm: UntypedFormGroup;
  public discountType: string;
  public discountTypeFormGroup: UntypedFormGroup;
  public discountNameFormGroup: UntypedFormGroup;
  public discountCodeFormGroup: UntypedFormGroup;
  public discountValueFormGroup: UntypedFormGroup;
  public discountScopeFormGroup: UntypedFormGroup;
  public discountRequirementsFormGroup: UntypedFormGroup;
  public discountDatesFormGroup: UntypedFormGroup;
  public maxRedeemableFormGroup: UntypedFormGroup;
  public codeCountFormGroup: UntypedFormGroup;

  // customerGetsFormGroup: FormGroup;

  public currentStep: string;
  public currentIndex = 0;
  public formSubmitting = false;
  public hasSubmissionErrors = false;
  public discountedItemsTableDataSource: SeFeTableDataSource;
  public requiredItemsTableDataSource: SeFeTableDataSource;
  public saleItemTableColumns = new BehaviorSubject<string[]>(['name', 'sku', 'price']);
  public discountedItemCount: number;
  public requiredItemCount: number;
  public steps: string[] = [];
  public showMaxValue = false;
  public showDiscountedItems = true;
  public submissionErrors: string[];
  public subscriptions: Subscription;
  public minimumItemCount: number;

  private readonly ALLOW_ANY_PRICE: number = -1;
  minItemPrice: number = this.ALLOW_ANY_PRICE;

  @ViewChild(SeFeTableComponent) public discountedItemsTable!: SeFeTableComponent<ElementRef>;
  @ViewChild('discountedItemsTable') set discountedItemsTableComponent(
    discountedItemsTable: SeFeTableComponent<ElementRef>
  ) {
    if (!discountedItemsTable) {
      return;
    }
    this.discountedItemsTable = discountedItemsTable;
    this.discountedItemsTable.dataSource = this.discountedItemsTableDataSource;
  }

  @ViewChild(SeFeTableComponent) public requiredItemsTable!: SeFeTableComponent<ElementRef>;
  @ViewChild('requiredItemsTable') set requiredItemsTableComponent(requiredItemsTable: SeFeTableComponent<ElementRef>) {
    if (!requiredItemsTable) {
      return;
    }
    this.requiredItemsTable = requiredItemsTable;
    this.requiredItemsTable.dataSource = this.requiredItemsTableDataSource;
  }

  customerBuysComponent: CustomerBuysComponent;
  @ViewChild(CustomerBuysComponent) set setCustomerBuysComponent(customerBuysComponent: CustomerBuysComponent) {
    if (!customerBuysComponent) {
      return;
    }
    this.customerBuysComponent = customerBuysComponent;
    this.discountForm.addControl('customerBuys', this.customerBuysComponent.form);
  }

  customerGetsComponent: CustomerGetsComponent;
  @ViewChild(CustomerGetsComponent) set setCustomerGetsComponent(customerGetsComponent: CustomerGetsComponent) {
    if (!customerGetsComponent) {
      return;
    }
    this.customerGetsComponent = customerGetsComponent;
    this.discountForm.addControl('customerGets', this.customerGetsComponent.form);
  }

  discountAmountConfig: SeFeFormFieldConfig = {
    label: 'Amount',
    required: true,
    errorMessages: {
      required: 'Please enter an amount.',
      min: 'Please enter an amount of at least $1',
      pattern: 'Please enter a valid amount',
      max: 'Discount value exceeded the limit',
    },
  };

  constructor(
    @Inject(CURRENT_ORG_ID) public currentOrgId: string,
    @Inject(DOCUMENT) private _document: Document,
    private _renderer2: Renderer2,
    public discountFormService: DiscountFormService,
    public discountService: DiscountService,
    private itemVariationService: ItemVariationService,
    private router: Router,
    private seFeScrollService: SeFeScrollService,
    private translateService: TranslateService,
    private toastService: SeFeToastService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.discountedItemsTableDataSource = new SeFeTableDataSource(undefined);
    this.requiredItemsTableDataSource = new SeFeTableDataSource(undefined);
    this.subscriptions = new Subscription();
    this.buildForm();
    if (this.formMode === 'edit') {
      this.showMaxValue = this.discount.maximum_discount_value_cents > 0;
      const type = this.discountService.getDiscountType(this.discount);
      this.steps = this.discountFormService.getStepsForType(type);
      this.currentIndex = this.steps.length - 1;
      this.currentStep = this.steps[this.currentIndex];
      this.updateValueFormGroup();
      this.updateScopeFormGroup();
      this.updateRequirementsFormGroup();
      this.updateMaxRedeemableFormGroup();
      this.loadSelectedItems();
    } else {
      this.currentStep = 'discount_type';
    }
    this.subscribeToDiscountValueChanges();
    [
      this.discountForm.valueChanges.subscribe(() => this.updateForm()),
      this.discountTypeFormGroup.valueChanges.subscribe((values: { type: string }) => {
        this.setType(values.type);
        if (values.type === 'automatic') {
          this.discountFormService.addBuyXGetYOption();
        } else {
          this.discountFormService.removeBuyXGetYOption();
        }
      }),
      this.discountNameFormGroup.valueChanges.subscribe(() =>
        this.checkStep('discount_name', this.discountNameFormGroup)
      ),
      this.discountCodeFormGroup.valueChanges.subscribe(() =>
        this.checkStep('discount_code', this.discountCodeFormGroup)
      ),
      this.discountValueFormGroup.valueChanges.subscribe((value: { value_type: string }) => {
        this.checkStep('discount_value', this.discountValueFormGroup);
      }),
      this.discountScopeFormGroup.valueChanges.subscribe(() =>
        this.checkStep('discount_scope', this.discountScopeFormGroup)
      ),
      this.discountRequirementsFormGroup.valueChanges.subscribe(() =>
        this.checkStep('discount_requirements', this.discountRequirementsFormGroup)
      ),
      this.maxRedeemableFormGroup.valueChanges.subscribe(() =>
        this.checkStep('max_redeemable', this.maxRedeemableFormGroup)
      ),
      this.discountDatesFormGroup.valueChanges.subscribe(() =>
        this.checkStep('discount_dates', this.discountDatesFormGroup)
      ),
      this.codeCountFormGroup.valueChanges.subscribe(() => this.checkStep('code_count', this.codeCountFormGroup)),
      this.discountValueFormGroup.controls.value_type.valueChanges.subscribe((value) => {
        if (!value) return;

        this.goToStep('discount_value');

        if (value === 'buyXGetY') {
          this.steps = this.discountFormService.getBuyXGetYSteps(this.steps);
        } else {
          const type = this.discountTypeFormGroup.value.type;
          this.steps = this.discountFormService.getStepsForType(type);
        }

        this.updateValueFormGroup();
      }),
      this.discountScopeFormGroup.controls.scope_type.valueChanges.subscribe(() => this.updateScopeFormGroup()),
      this.discountScopeFormGroup.controls.max_eligible_item_type.valueChanges.subscribe(() => {
        this.discountFormService.setMaxEligibleCountValidation(this.discountScopeFormGroup);
      }),
      this.discountRequirementsFormGroup.controls.requirement_type.valueChanges.subscribe(() => {
        this.updateRequirementsFormGroup();
      }),
      this.maxRedeemableFormGroup.controls.max_use.valueChanges.subscribe(() => this.updateMaxRedeemableFormGroup()),

      // this.customerGetsFormGroup.controls.discountWhat.valueChanges.subscribe((value) => {
      //   if (!value) return;
      //
      //   if (value === 'amount') {
      //     this.customerGetsFormGroup.controls.discountValue.setValidators([Validators.required, Validators.min(0.01), Validators.pattern(/^\d+(\.\d{1,2})?$/)])
      //   } else if (value === 'percentage') {
      //     this.customerGetsFormGroup.controls.discountValue.setValidators([Validators.required, Validators.min(1)]);
      //   } else {
      //     this.customerGetsFormGroup.controls.discountValue.clearValidators();
      //   }
      //
      //   this.customerGetsFormGroup.controls.discountValue.updateValueAndValidity();
      // }),
      // this.customerGetsFormGroup.controls.discountWhatScope.valueChanges.subscribe(value => {
      //   if (!value) return;
      //
      //   if (value === 'Item') {
      //     this.customerGetsFormGroup.controls.howManyItems.setValidators(
      //       [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]
      //     );
      //   } else {
      //     this.customerGetsFormGroup.controls.howManyItems.clearValidators();
      //   }
      //
      //   this.customerGetsFormGroup.controls.howManyItems.updateValueAndValidity();
      // }),
      // this.customerGetsFormGroup.controls.limitNumberOfUses.valueChanges.subscribe(value => {
      //   if (value) {
      //     this.customerGetsFormGroup.controls.maxUses.setValidators(
      //       [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]
      //     );
      //   } else {
      //     this.customerGetsFormGroup.controls.maxUses.clearValidators();
      //   }
      //   this.customerGetsFormGroup.controls.maxUses.updateValueAndValidity();
      // }),
    ].forEach((sub) => this.subscriptions.add(sub));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  minimumCountUpdated(value: number): void {
    if (!this.customerGetsComponent) return;

    this.customerGetsComponent.minimumItemCount = value;
  }

  public isReusable(): boolean {
    if (!this.discountTypeFormGroup) {
      return false;
    }
    return ['reusable', 'automatic'].includes(this.discountTypeFormGroup.value.type);
  }

  public canEdit(step) {
    if (this.formMode === 'create') {
      return true;
    }
    if (step === 'discount_type' || step === 'discount_code') {
      return false;
    }
    return this.discount.used_code_count === 0;
  }

  public toggleMaxValue(): void {
    this.discountFormService.setMaxValueValidation(this.discountValueFormGroup, !this.showMaxValue);
    this.showMaxValue = !this.showMaxValue;
  }

  public updateDiscountedItems(items: number[]): void {
    this.discountScopeFormGroup.controls.discounted_item_variation_ids.setValue(items);
  }

  public updateRequiredItems(items: number[]): void {
    this.discountRequirementsFormGroup.controls.required_item_variation_ids.setValue(items);
  }

  public showEmptyState(): boolean {
    return !this.steps.length;
  }

  public showStep(stepID: string): boolean {
    if (this.steps) {
      if (this.steps.indexOf(stepID) > 0) {
        return this.steps.indexOf(stepID) <= this.steps.indexOf(this.currentStep);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public excludeDatesAfterEnd() {
    if (!this.discountDatesFormGroup.value.starts_at) {
      return [];
    }
    const today = DateTime.now().startOf('day');
    const startDate = DateTime.fromFormat(
      this.discountDatesFormGroup.value.starts_at,
      this.discountFormService.UI_DATE_FORMAT
    );
    const startBlockDate = today > startDate ?  startDate : today;
    return [
      {
        end: startBlockDate.minus({ day: 1 }).toFormat(this.discountFormService.DB_DATE_FORMAT),
      },
    ];
  }

  public excludeDatesBeforeStart() {
    if (!this.discountDatesFormGroup.value.starts_at) {
      return [];
    }

    const today = DateTime.now().startOf('day');
    const startDate = DateTime.fromFormat(
      this.discountDatesFormGroup.value.starts_at,
      this.discountFormService.UI_DATE_FORMAT
    );
    const startBlockDate = today > startDate ? startDate : today;
    return [
      {
        end: startBlockDate.minus({ day: 1 }).toFormat(this.discountFormService.DB_DATE_FORMAT)
      }];
  }

  public validateAndStep(step: EditableStepComponent): void {
    const formGroup =
      step.stepID === 'customer_buys'
        ? this.customerBuysComponent.form
        : step.stepID === 'customer_gets'
        ? this.customerGetsComponent.form
        : step.formGroup;

    if (step.stepID === 'customer_gets' && !this.customerBuysComponent.minimumItemCountControl.valid) {
      this.customerBuysComponent.minimumItemCountControl.markAsTouched();
      this.customerBuysComponent.minimumItemCountControl.markAsDirty();
      this.customerBuysComponent.customerBuysControl.setValue([]);
      return;
    }
    if (formGroup.valid) {
      if (step.isLastStep()) {
        if (
          this.discountValueFormGroup.value.value_type === 'buyXGetY' &&
          (!this.customerGetsComponent.form.valid || !this.customerBuysComponent.form.valid)
        ) {
          return;
        }
        this.submitForm();
      } else {
        this.goToStep(this.steps[this.currentIndex + 1]);
      }
    } else {
      Object.keys(formGroup.controls).forEach((key) => {
        formGroup.controls[key].setValue(formGroup.controls[key].value);
        formGroup.controls[key].markAsDirty();
        formGroup.controls[key].markAsTouched();
      });
    }
  }

  getDiscountValueDescription(value: string): string {
    switch (value) {
      case 'amount':
        return 'A set dollar amount off the cart subtotal or specific items you choose';
      case 'percentage':
        return 'A percentage off the cart subtotal or specific items you choose';
      case 'buyXGetY':
        return (
          'Great for making “sibling” discounts where your customers get a discount ' +
          'for registering more than one athlete or buying more of the same item. ' +
          'Commonly used for BOGO discounts.'
        );
    }
  }

  subscribeToDiscountValueChanges(): void {
    const discountValueInput = this.discountForm.get('discountValue');
    discountValueInput.valueChanges.subscribe((value) => {
      if (value.value_type === 'amount') {
        this.minItemPrice = Number(value.value_amount);
      } else {
        this.minItemPrice = this.ALLOW_ANY_PRICE;
      }
    });
  }

  onMinimumItemCountUpdated(count: number) {
    this.minimumItemCount = count;
  }

  private updateForm(): void {
    this.mapFormToModel();
    this.updateSummary.emit();
  }

  private setType(type: string): void {
    this.discount.single_use = !this.isReusable();
    this.resetForm();
    this.steps = this.discountFormService.getStepsForType(type);
  }

  private checkStep(step: string, formGroup: UntypedFormGroup): void {
    if (!this.steps.length) {
      return;
    }
    if (this.currentIndex < this.steps.indexOf(step)) {
      return;
    }
    if (formGroup.invalid && this.currentIndex > this.steps.indexOf(step)) {
      this.goToStep(step);
      if (this.formMode === 'create') {
        this.resetSubsequentSteps(step);
      }
    }
  }

  private goToStep(step: string): void {
    this.currentStep = step;
    this.currentIndex = this.steps.indexOf(step);
    const element = this.elementRef.nativeElement.querySelector(`#${step}`);
    if (element) {
      this.seFeScrollService.scrollTo(SeFeScrollToAlignment.Top, element);
    }
  }

  private updateValueFormGroup(): void {
    this.discountFormService.setValueValidation(this.discountValueFormGroup, this.showMaxValue);
    this.discountValueFormGroup.controls.value_amount.updateValueAndValidity();
    if (this.discountValueFormGroup.controls.value_type.value === 'amount') {
      this.showMaxValue = false;
    }
  }

  private updateScopeFormGroup(): void {
    this.showDiscountedItems = this.discountScopeFormGroup.controls.scope_type.value === 'Item';
    this.discountFormService.setScopeValidation(this.discountScopeFormGroup, this.showDiscountedItems);
  }

  private updateRequirementsFormGroup(): void {
    this.discountFormService.setRequirementsValidation(this.discountRequirementsFormGroup);
  }

  private updateMaxRedeemableFormGroup(): void {
    this.discountFormService.setMaxRedeemableValidation(this.maxRedeemableFormGroup, this.discount.used_code_count);
  }

  private mapFormToModel(): void {
    this.discount.discount_style = this.discountForm.value.discountType.type === 'automatic' ? 'Automatic' : 'Code';
    this.discount.name = this.discountForm.value.discountName?.name?.trim();
    this.discount.expires_at = this.discountFormService.formatDateForSubmission(
      this.discountForm.value.discountDates.expires_at
    );
    this.discount.starts_at = this.discountFormService.formatDateForSubmission(
      this.discountForm.value.discountDates.starts_at
    );
    this.discount.max_use_count = this.discountForm.value.maxRedeemable.max_use_count;

    if (this.discountValueFormGroup.value.value_type === 'buyXGetY') {
      this.mapBuyXGetYFormToModel();
    } else {
      this.discount.buyx_gety = false;
      this.discount.max_use = this.discountForm.value.maxRedeemable.max_use;
      this.discount.additional_code_count = this.discountForm.value.codeCount.additional_code_count;
      this.discount.code_count = this.discountForm.value.codeCount.code_count;
      this.discount.discount_scope = this.discountForm.value.discountScope.scope_type;
      this.discount.discount_type = this.discountForm.value.discountValue.value_type;
      this.discount.discount_value = this.discountForm.value.discountValue.value_amount;
      this.discount.discounted_item_variation_ids = this.discountForm.value.discountScope.discounted_item_variation_ids;
      this.discount.max_eligible_items = this.discountFormService.getMaxEligibleCountValue(
        this.discountForm.value.discountScope
      );
      this.discount.maximum_discount_value = this.discountForm.value.discountValue.maximum_discount_value;
      this.discount.minimum_sale_total = this.discountForm.value.discountRequirements.minimum_sale_total;
      this.discount.required_item_variation_ids =
        this.discountForm.value.discountRequirements.required_item_variation_ids;
      this.discount.required_items_quantity = this.discountForm.value.discountRequirements.required_items_quantity;
      this.discount.requirement_type = this.discountForm.value.discountRequirements.requirement_type;
      this.discount.supplied_code = this.isReusable() ? this.discountForm.value.discountCode.supplied_code : null;
    }
  }

  private mapBuyXGetYFormToModel(): void {
    this.discount.buyx_gety = true;
    this.discount.discount_scope = 'Item';
    if (this.customerBuysComponent) {
      this.discount.requirement_type = 'Quantity';
      this.discount.required_item_variation_ids = this.customerBuysComponent.value.customerBuys.map(Number);
      this.discount.required_items_quantity = this.customerBuysComponent.value.minimumItemCount;
    }

    if (this.customerGetsComponent) {
      this.discount.discounted_item_variation_ids = this.customerGetsComponent.value.customerGets.map(Number);
      this.discount.discount_type = this.customerGetsComponent.value.discountWhat;
      this.discount.discount_value =
        this.discount.discount_type === 'free' ? '0' : String(this.customerGetsComponent.value.discountValue);
      this.discount.max_eligible_items = this.customerGetsComponent.value.howManyItems || null;
    }
  }

  private buildForm(): void {
    this.discountTypeFormGroup = this.discountFormService.getDiscountTypeFormGroup(this.discount);
    this.discountNameFormGroup = this.discountFormService.getDiscountNameFormGroup(this.discount);
    this.discountCodeFormGroup = this.discountFormService.getDiscountCodeFormGroup(this.discount);
    this.discountValueFormGroup = this.discountFormService.getDiscountValueFormGroup(this.discount);
    this.discountScopeFormGroup = this.discountFormService.getDiscountScopeFormGroup(this.discount);
    this.discountRequirementsFormGroup = this.discountFormService.getDiscountRequirementsFormGroup(this.discount);
    this.maxRedeemableFormGroup = this.discountFormService.getMaxRedeemableFormGroup(
      this.discount,
      this.formMode === 'edit'
    );
    this.discountDatesFormGroup = this.discountFormService.getDiscountDatesFormGroup(this.discount);
    this.codeCountFormGroup = this.discountFormService.getCodeCountFormGroup(this.discount);

    this.discountForm = new UntypedFormGroup({
      discountType: this.discountTypeFormGroup,
      discountName: this.discountNameFormGroup,
      discountCode: this.discountCodeFormGroup,
      discountValue: this.discountValueFormGroup,
      discountScope: this.discountScopeFormGroup,
      discountRequirements: this.discountRequirementsFormGroup,
      maxRedeemable: this.maxRedeemableFormGroup,
      discountDates: this.discountDatesFormGroup,
      codeCount: this.codeCountFormGroup,
    });
  }

  private resetForm() {
    this.currentStep = 'discount_type';
    this.currentIndex = 0;
    this.showMaxValue = false;
    this.showDiscountedItems = false;
    this.hasSubmissionErrors = false;
    this.submissionErrors = [];
    this.resetSubsequentSteps('discount_type');
  }

  private resetSubsequentSteps(current_step: string): void {
    const current_step_index = this.steps.indexOf(current_step);
    this.steps.forEach((step, index) => {
      if (index > current_step_index) {
        switch (step) {
          case 'discount_name':
            this.discountNameFormGroup.reset();
            break;
          case 'discount_code':
            this.discountCodeFormGroup.reset();
            break;
          case 'discount_value':
            this.discountValueFormGroup.reset();
            break;
          case 'discount_scope':
            this.discountScopeFormGroup.reset();
            break;
          case 'discount_requirements':
            this.discountRequirementsFormGroup.reset();
            break;
          case 'max_redeemable':
            this.maxRedeemableFormGroup.reset();
            break;
          case 'discount_dates':
            this.discountFormService.setDiscountDates(this.discount, this.discountDatesFormGroup);
            Object.keys(this.discountDatesFormGroup.controls).forEach((key) => {
              this.discountDatesFormGroup.controls[key].markAsPristine();
              this.discountDatesFormGroup.controls[key].markAsUntouched();
            });
            break;
          case 'code_count':
            this.codeCountFormGroup.reset();
            break;
          case 'customer_buys':
            this.customerBuysComponent.form.reset();
            break;
          case 'customer_gets':
            this.customerGetsComponent.form.reset();
            break;
        }
      }
    });
  }

  private async submitForm() {
    this.currentIndex = this.steps.length;
    this.discount.organization_id = this.currentOrgId.toString();
    this.formSubmitting = true;
    await this.discountService
      .save(this.discount)
      .then(() => {
        const goTo = this.discount.id ? `/discounts/${this.discount.id}/detail` : '/discounts';
        const successKey = this.discount.id ? 'DISCOUNTS.EDIT_DISCOUNT.success' : 'DISCOUNTS.ADD_DISCOUNT.success';
        this.router.navigate([goTo]);
        const successMessage = this.translateService.instant(successKey, { name: this.discount.name });
        this.toastService.addToast({
          type: SeFeToastTypes.Success,
          message: successMessage,
        });
      })
      .catch((e) => {
        this.formSubmitting = false;
        this.hasSubmissionErrors = true;
        this.submissionErrors = e.error.error.messages;
        this.seFeScrollService.scrollTo(SeFeScrollToAlignment.Top, document.getElementById('submission_errors'), {
          offset: 115,
        });
      });
  }

  private loadSelectedItems(): void {
    if (this.discount.discounted_item_variation_ids?.length) {
      const queryParams = {
        id_list: this.discount.discounted_item_variation_ids.join(','),
        'order[full_name]': 'asc',
        unpaginated: true,
        include_zero_rate: 1,
      };
      this.itemVariationService.getItemVariationsByID(queryParams).subscribe((response) => {
        this.discountedItemsTableDataSource.data = response.result;
        this.discountedItemCount = response.result.length;
      });
    }
    if (this.discount.required_item_variation_ids?.length) {
      const queryParams = {
        id_list: this.discount.required_item_variation_ids.join(','),
        'order[full_name]': 'asc',
        include_zero_rate: 1,
      };
      this.itemVariationService.getItemVariationsByID(queryParams).subscribe((response) => {
        this.requiredItemsTableDataSource.data = response.result;
        this.requiredItemCount = response.result.length;
      });
    }
  }
}
